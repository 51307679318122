
import React, { useState, useEffect } from "react";
import { Modal, Icon, message, Button, Checkbox, } from "antd";
import ImageWithFallback from "@/ImageWithFallback/";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { get, post,put } from "$ACTIONS/TlcRequest";
import { ApiPort } from "$ACTIONS/TLCAPI";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Superdoor = () => {
    const [shouldDisplay, setShouldDisplay] = useState(false);
    const [isChecked, onChangeRadio] = useState(false);
    const [domainList, updateDomainList] = useState([]);
   
    useEffect(() => {
        get(ApiPort.GetMemberSuperdoorShownStatus).then((res) => {
            if (res?.isSuccess && res.result?.isSuperdoorShown) {
                updateDomainList(res.result.domainList)
                setShouldDisplay(res.result.isSuperdoorShown)
            }
        }).catch((error) => {

        })
    },[])

    const openNewWindow =(link)=> {
        if(link.indexOf("https://") == -1){
            window.open("https://" + link,"_blank")
        }else {
            window.open(link,"_blank")
        }
    }
    const knew =()=>{
        setShouldDisplay(false)
        if(isChecked){
            put(ApiPort.PutUpdateMemberSuperdoorShownStatus).then((res) => {
                console.log("🚀 ~ put ~ res:", res)
            })
        }
    }
    return (
        <Modal
            title="贴心提示"
            closable={false}
            className="modal-pubilc superdoor-modal"
            zIndex={1999}
            visible={shouldDisplay}
            onOk={() => {
                setShouldDisplay(false)
            }}
            onCancel={() => {
                setShouldDisplay(false)
            }}
            width={600}
            footer={null}
            centered={true}
            maskClosable={false}
        >
            <React.Fragment>
                <ImageWithFallback
                    src={`/cn/img/home/Safehouse-desktop_CN_1800x450.png`}
                    priority
                    width={600}
                    height={150}
                    onClick={() => { }}
                    alt={"F88全新升级"}
                    local={true}
                />
                <div className="superdoor-modal-section">
                    <h3>尊贵的乐天堂会员们，</h3>
                    <p>当您不能访问我们的网站时，您可以使用任意浏览器访问以下链接，来继续享受游戏带给您的愉快体验。乐天堂建议点击复制并收藏这些域名，以便您随时畅享。</p>
                    <div className="domain-wrap" style={{display:domainList?.length ?"block":"none"}}>
                        {domainList.map((item, index) => {
                            return <div key={"domain"+index} className="item-domain">
                                <div className="left">
                                    {item}
                                    <CopyToClipboard
                                        text={item}
                                        onCopy={() => {
                                            message.success(
                                                "复制成功！"
                                            );
                                        }}
                                    >
                                        <div className="homeinstallurl">
                                            <img
                                                src="/cn/img/icons/copy.png"
                                                className="copy-icon"
                                                alt="copy"
                                            />
                                        </div>
                                    </CopyToClipboard>
                                </div>
                                
                                <div className="goTo" onClick={()=>openNewWindow(item)}>前往</div>
                            </div>
                        })}
                    </div>
                    <div className="checkbox-button-container">
                        <Checkbox checked={isChecked} onChange={() => onChangeRadio(!isChecked)}>
                            <Button
                                type={isChecked ? 'primary' : 'default'}
                                icon={isChecked ? <CheckOutlined /> : <CloseOutlined />}
                            />
                        </Checkbox>
                        7日内不再显示此提示
                    </div>
                    <div className="know"><Button size="large" block type="primary" onClick={() => knew()}>知道了</Button></div>
                </div>
            </React.Fragment>
        </Modal>
    )
}

export default Superdoor;